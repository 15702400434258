<template>
    <svg width="609px" height="100px" viewBox="0 0 609 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>index-home</title>
        <defs>
            <linearGradient x1="51.7084921%" y1="21.0490079%" x2="50%" y2="100%" id="linearGradient-1">
                <stop stop-color="#00BD6E" offset="0%"></stop>
                <stop stop-color="#007380" offset="100%"></stop>
            </linearGradient>
            <path d="M54.899516,0 C55.9013139,0 56.552433,0 56.8528732,0 C79.1738541,0 97.3417387,18.0736442 97.9640058,40.6083911 L98,40.5522333 L98,93.9357634 C98,95.0431524 97.1022838,95.9408686 95.9948948,95.9408686 C95.4557553,95.9408686 94.9393312,95.7237521 94.5621553,95.3385122 L71.5686045,71.8533579 C70.5356489,70.7983176 70.553552,69.1056625 71.6085923,68.0727069 C71.6474806,68.0346326 71.6875225,67.9977547 71.7286619,67.9621247 L73.2344406,66.6580003 L73.2344406,66.6580003 C78.1985567,61.458619 81.0455853,54.1526984 81.0455853,46.3114754 C81.0455853,31.2440899 69.4513557,18.8822366 54.8368139,18.0327869 L21.9559284,18.0327869 C19.2171585,18.0327869 16.5936197,16.9306016 14.6765803,14.9746312 L3.34076197,3.4086059 C2.56562991,2.61773252 2.57839078,1.34823429 3.36926415,0.573102236 C3.74406963,0.205756778 4.24795842,9.84583805e-16 4.77276489,0 L7.25781361,0 L7.25781361,0 L54.899516,0 Z" id="path-2"></path>
            <linearGradient x1="47.5130407%" y1="23.7818145%" x2="93.0815227%" y2="59.6120386%" id="linearGradient-4">
                <stop stop-color="#00BD6E" offset="0%"></stop>
                <stop stop-color="#007380" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="5.19457999%" y1="20.582372%" x2="83.1393126%" y2="20.582372%" id="linearGradient-5">
                <stop stop-color="#00BD6E" offset="0%"></stop>
                <stop stop-color="#007380" offset="100%"></stop>
            </linearGradient>
            <path d="M42.0140609,63.1147541 C19.0423731,63.1147541 0.376646055,44.3758665 0.0056286838,21.1166553 L0,21.1166553 L0,5.73770492 L0.000283779943,5.44859622 C0.00323653711,2.4403929 2.4426957,0.00332706418 5.45090047,0.00332706418 L5.45090047,0.00332706418 L5.45090047,0.00332706418 L53.2866686,0 C58.1857994,0 62.1573297,4.03677685 62.1573297,9.01639344 C62.1573297,13.9169685 58.3108791,17.9043977 53.5192472,18.029748 L53.2866686,18.0327869 L45.8854368,18.0327869 C42.1941402,18.0327869 39.2017531,21.025174 39.2017531,24.7164706 C39.2017531,26.4799487 39.8986978,28.1719648 41.1407308,29.4238471 L71.176139,59.697436 C71.9560827,60.4835647 71.9510688,61.7531171 71.1649401,62.5330608 C70.7893755,62.9056707 70.2817706,63.1147541 69.7527272,63.1147541 L42.0140609,63.1147541 L42.0140609,63.1147541 Z" id="path-6"></path>
            <linearGradient x1="86.3795401%" y1="65.0063718%" x2="38.9430446%" y2="24.3063304%" id="linearGradient-8">
                <stop stop-color="#00BD6E" offset="0%"></stop>
                <stop stop-color="#007380" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="logo" transform="translate(-1850.000000, -1457.000000)">
                <g id="bz-22" transform="translate(1850.000000, 1457.000000)">
                    <g id="bz-31">
                        <g id="tyx1">
                            <mask id="mask-3" fill="white">
                                <use xlink:href="#path-2"></use>
                            </mask>
                            <use id="mask-4" fill="url(#linearGradient-1)" xlink:href="#path-2"></use>
                            <path d="M44.0144124,78.7853222 C65.0695406,78.7853222 81.4487971,62.2243589 81.4487971,44.359673 C81.4487971,24.0542689 71.8265682,0 48.1935763,0 C41.2685629,0 25.9334512,0 2.18824105,0 C-0.326920589,19.4616402 -1.58450141,32.8067476 -1.58450141,40.0353222 C-1.58450141,61.4363562 22.9592842,78.7853222 44.0144124,78.7853222 Z" fill="url(#linearGradient-4)" mask="url(#mask-3)"></path>
                        </g>
                        <g id="tyx2" transform="translate(0.000000, 36.885246)">
                            <mask id="mask-7" fill="white">
                                <use xlink:href="#path-6"></use>
                            </mask>
                            <use id="mask-8" fill="url(#linearGradient-5)" xlink:href="#path-6"></use>
                            <path d="M16.2447529,18.0327869 L81.4487971,18.0327869 L81.4487971,18.0327869 L81.4487971,72.9508197 L24.2252494,72.9508197 L8.604375,46.1035024 C6.59233095,42.6454383 5.47433104,38.740237 5.35155034,34.7413054 L5.18858662,29.4336248 C5.00110714,23.3274728 9.79913922,18.2254765 15.9052912,18.037997 C16.0184118,18.0345238 16.131579,18.0327869 16.2447529,18.0327869 Z" id="jx2" fill="url(#linearGradient-8)" opacity="0.680000007" mask="url(#mask-7)"></path>
                        </g>
                    </g>
                    <g id="res-1" transform="translate(138.000000, 28.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <path d="M300.681717,0 L300.681717,38.403905 C300.681717,44.2363885 295.948708,48.9645507 290.110244,48.9645507 L286.092128,48.9645507 L286.092128,10.6244691 C286.075066,7.80428711 287.187889,5.09444747 289.182642,3.09874578 C291.177394,1.10304409 293.88834,-0.0127035965 296.711436,0 L300.681717,0 Z" id="path1"></path>
                        <g id="bz-17" transform="translate(121.010274, 0.000000)">
                            <path d="M53.4173592,24.8645066 C53.4173592,39.2002246 42.0964752,49.9997989 26.7096399,49.9997989 C11.3228047,49.9997989 0.00192067757,39.3276532 0.00192067757,24.8645066 C0.00192067757,10.4013599 11.3387496,0 26.7096399,0 C42.0805303,0 53.4173592,10.5447171 53.4173592,24.8645066 Z M14.8944638,24.8645066 C14.7695776,28.171357 15.96704,31.3919357 18.2225907,33.815514 C20.4781414,36.2390922 23.6063685,37.6664459 26.9169237,37.7825814 C33.2948865,37.7825814 38.524816,32.2712942 38.524816,24.8645066 C38.524816,17.4577189 33.3586662,12.1216461 26.9169237,12.1216461 C23.6105163,12.1914323 20.4717041,13.5890805 18.2094122,15.998921 C15.9471203,18.4087614 14.752175,21.6275221 14.8944638,24.9282209 L14.8944638,24.8645066 Z" id="形状"></path>
                            <path d="M26.7096399,0 C11.3387496,0 0.00192067758,10.4810028 0.00192067758,24.8645066 C-0.0882256768,32.0408134 2.99878241,38.8905664 8.43677649,43.5805829 C11.6257579,40.2355821 14.6712351,36.747224 17.7007675,33.2429374 C15.8563333,30.8652862 14.8677106,27.9361242 14.8944638,24.9282209 C14.7697534,21.6383171 15.9725541,18.4361517 18.2329502,16.0403099 C20.4933463,13.6444681 23.6220365,12.2555944 26.9169237,12.1853604 C33.2948865,12.1853604 38.524816,17.5692189 38.524816,24.9282209 C38.530064,25.9991585 38.417779,27.067444 38.189973,28.113936 C36.8565471,33.8339051 38.5335013,39.8383373 42.638602,44.0425116 L43.4358474,44.8230118 C49.8004195,40.1904247 53.5211984,32.7624265 53.4173592,24.8963637 C53.4173592,10.5447171 42.0964752,0 26.7096399,0 Z" id="path2"></path>
                        </g>
                        <path d="M334.070353,11.3891327 C331.630782,11.3891327 330.020346,12.2174186 330.020346,14.0332762 C330.020346,21.2329924 355.611922,16.9641341 355.611922,34.6448531 C355.611922,45.046213 346.379821,50 335.39378,50 C326.921215,49.9749043 318.675206,47.2643722 311.843152,42.2587122 L312.273665,41.366712 C314.771122,36.3518961 320.749206,34.1517001 325.90656,36.3492107 C328.924926,37.7088853 332.167817,38.5025798 335.473505,38.6907113 C338.550872,38.6907113 340.512095,37.6394253 340.512095,35.4094247 C340.512095,28.002637 315.000244,32.6856383 315.000244,15.4349909 C315.000244,6.00527409 322.972698,0 335.282166,0 C342.496098,-0.0228307589 349.5779,1.93313859 355.755426,5.65484543 L354.862512,7.51848878 C352.564256,12.3722327 346.994103,14.7403739 341.899302,13.0297759 C339.38841,12.0881659 336.748362,11.5349143 334.070353,11.3891327 Z" id="path3"></path>
                        <g id="bz-16" transform="translate(239.851704, 0.000000)">
                            <path d="M14.5258103,36.8268669 L22.9128314,36.8268669 C29.6319078,36.8268669 35.0787954,42.2681756 35.0787954,48.9803701 L1.5944907,48.9803701 C0.713877803,48.9803701 9.96022396e-16,48.2664923 0,47.3858794 L0,0 L0,0 C8.02496205,0.0087902932 14.5258151,6.51011358 14.5258103,14.526861 L14.5258103,36.8268669 Z" id="path4"></path>
                            <path d="M2.45551568,48.9644415 L1.5944907,48.9644415 C0.713877803,48.9644415 3.29888582e-16,48.2505637 0,47.3699508 L0,0 L0,0 C8.03118963,0 14.5417552,6.5038972 14.5417552,14.526861 L14.5417552,36.8268669 C14.5587291,40.0400788 13.2928583,43.1275061 11.0244064,45.4055931 C8.75595444,47.68368 5.67206689,48.9644415 2.45551568,48.9644415 Z" id="path5"></path>
                        </g>
                        <g id="bz-19" transform="translate(0.000000, 0.031857)">
                            <path d="M25.8307494,43.0549399 L22.2272004,35.7596523 L14.5417552,35.7596523 L14.5417552,47.3699526 C14.5417552,48.2505655 13.8278774,48.9644433 12.9472645,48.9644433 L1.5944907,48.9644433 C0.713877803,48.9644433 1.07843977e-16,48.2505655 0,47.3699526 L0,0 L0,0 L22.8649967,0 C36.0036,0 43.5933758,6.37143025 43.5933758,17.3940046 C43.5933758,24.6574351 40.643568,29.9616508 35.3339139,32.956223 L45.267591,48.9644433 L35.317969,48.9644433 C31.2851804,48.9668046 27.6034703,46.6735013 25.8307494,43.0549399 Z M14.5417552,24.5777922 L22.8649967,24.5777922 C27.2020114,24.5777922 29.7053618,22.2044344 29.7053618,17.8081476 C29.7053618,13.4118607 27.2020114,11.1659315 22.8649967,11.1659315 L14.5417552,11.1659315 L14.5417552,24.5777922 Z" id="形状"></path>
                            <path d="M14.5417552,11.1659315 C14.5417552,5.00795493 9.54461058,0.0159285756 3.38032029,0.0159285756 L0,0.0159285756 L0,47.3699508 C-7.71356975e-15,48.2505637 0.713877803,48.9644415 1.5944907,48.9644415 L12.9472645,48.9644415 C13.8278774,48.9644415 14.5417552,48.2505637 14.5417552,47.3699508 L14.5417552,11.1659315 L14.5417552,11.1659315 Z" id="path6"></path>
                        </g>
                        <g id="bz-20" transform="translate(185.591185, 0.000000)">
                            <path d="M43.0990837,1.5944907 L43.0990837,10.0015124 C43.0990837,10.8821253 42.3852059,11.5960031 41.504593,11.5960031 L28.8443368,11.5960031 L28.8443368,11.5960031 L28.8443368,48.9644415 L24.7624406,48.9644415 C21.9601756,48.9644415 19.2729164,47.8513004 17.2929118,45.8703359 C15.3129073,43.8893715 14.2026791,41.2031875 14.2069122,38.4037959 L14.2069122,11.5960031 L1.5944907,11.5960031 C0.713877803,11.5960031 -5.58289838e-16,10.8821253 0,10.0015124 L0,1.5944907 C9.49360524e-16,0.713877803 0.713877803,-6.02786398e-17 1.5944907,0 L41.504593,0 C42.3852059,-3.85042822e-15 43.0990837,0.713877803 43.0990837,1.5944907 Z" id="path7"></path>
                            <rect id="jx" x="0.031889814" y="0" width="43.0671939" height="11.5960031" rx="1.5944907"></rect>
                        </g>
                        <g id="bz-18" transform="translate(56.429026, 0.000000)">
                            <path d="M53.4176315,24.8645066 C53.4176315,39.2002246 42.0808026,49.9997989 26.7099123,49.9997989 C11.3390219,49.9997989 0.00219301184,39.3276532 0.00219301184,24.8645066 C0.00219301184,10.4013599 11.323077,0 26.7099123,0 C42.0967475,0 53.4176315,10.5447171 53.4176315,24.8645066 Z M14.8947362,24.8645066 C14.7655468,28.1724999 15.9615522,31.3955009 18.2179937,33.8200364 C20.4744352,36.2445718 23.6051867,37.6706501 26.9171961,37.7825814 C33.2951589,37.7825814 38.5250884,32.2712942 38.5250884,24.8645066 C38.5250884,17.4577189 33.3429936,12.1216461 26.9171961,12.1216461 C23.6107887,12.1914323 20.4719764,13.5890805 18.2096845,15.998921 C15.9473927,18.4087614 14.7524473,21.6275221 14.8947362,24.9282209 L14.8947362,24.8645066 Z" id="形状"></path>
                            <path d="M26.7099123,0 C11.323077,0 0.00219301184,10.4810028 0.00219301184,24.8645066 C-0.0941369073,32.0386973 2.98723987,38.8889043 8.42110392,43.5805829 C11.6100853,40.2355821 14.6715075,36.747224 17.6850949,33.2429374 C15.8452521,30.8638012 14.8621779,27.9344347 14.8947362,24.9282209 C14.7700257,21.6383171 15.9728264,18.4361517 18.2332225,16.0403099 C20.4936186,13.6444681 23.6223088,12.2555944 26.9171961,12.1853604 C33.2951589,12.1853604 38.5250884,17.5692189 38.5250884,24.9282209 C38.5223391,25.9987449 38.4101425,27.0661891 38.1902453,28.113936 C36.8434665,33.8313592 38.5155251,39.8397983 42.6229295,44.0425116 L43.4201748,44.8230118 C49.7949771,40.1974381 53.5231532,32.7665429 53.4176315,24.8963637 C53.4176315,10.5447171 42.1445823,0 26.7099123,0 Z" id="path8"></path>
                        </g>
                        <g id="bz-15" transform="translate(366.916861, 0.000000)">
                            <path d="M43.0831388,1.5944907 L43.0831388,10.0015124 C43.0831388,10.8821253 42.369261,11.5960031 41.4886481,11.5960031 L28.7327224,11.5960031 L28.7327224,11.5960031 L28.7327224,48.9644415 L24.698661,48.9644415 C18.8601975,48.9644415 14.1271876,44.2362794 14.1271876,38.4037959 L14.1271876,11.5960031 L1.62638052,11.5960031 C0.745767617,11.5960031 0.031889814,10.8821253 0.031889814,10.0015124 L0.031889814,1.5944907 C0.031889814,0.713877803 0.745767617,7.26800093e-12 1.62638052,7.2685053e-12 L41.4886481,7.2685053e-12 C42.369261,7.26812149e-12 43.0831388,0.713877803 43.0831388,1.5944907 Z" id="path9"></path>
                            <rect id="jx" x="0" y="0" width="43.0671939" height="11.5960031" rx="1.5944907"></rect>
                        </g>
                    </g>
                    <g id="bz-21" opacity="0.5" transform="translate(557.000000, 17.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <path d="M48,0 C50.209139,0 52,1.790861 52,4 L52,21 C52,23.209139 50.209139,25 48,25 L4,25 C1.790861,25 0,23.209139 0,21 L0,4 C0,1.790861 1.790861,0 4,0 L48,0 Z M48,1 L4,1 C2.40231912,1 1.09633912,2.24891996 1.00509269,3.82372721 L1,4 L1,21 C1,22.5976809 2.24891996,23.9036609 3.82372721,23.9949073 L4,24 L48,24 C49.5976809,24 50.9036609,22.75108 50.9949073,21.1762728 L51,21 L51,4 C51,2.40231912 49.75108,1.09633912 48.1762728,1.00509269 L48,1 Z" id="jx3"></path>
                        <g id="Beta" transform="translate(7.260000, 6.148000)">
                            <path d="M0,0 L0,12.852 L5.904,12.852 C7.272,12.852 8.352,12.6 9.108,12.096 C9.99,11.484 10.44,10.53 10.44,9.234 C10.44,8.37 10.224,7.668 9.81,7.164 C9.378,6.642 8.748,6.3 7.902,6.138 C8.55,5.886 9.036,5.544 9.378,5.076 C9.72,4.572 9.9,3.96 9.9,3.24 C9.9,2.268 9.558,1.494 8.892,0.918 C8.172,0.306 7.164,0 5.886,0 L0,0 Z M2.106,1.728 L5.364,1.728 C6.228,1.728 6.84,1.872 7.236,2.16 C7.596,2.43 7.794,2.88 7.794,3.492 C7.794,4.158 7.596,4.644 7.236,4.95 C6.858,5.238 6.228,5.4 5.328,5.4 L2.106,5.4 L2.106,1.728 Z M2.106,7.128 L5.652,7.128 C6.588,7.128 7.272,7.29 7.704,7.614 C8.118,7.938 8.334,8.46 8.334,9.198 C8.334,9.918 8.046,10.422 7.47,10.746 C7.02,10.998 6.39,11.124 5.598,11.124 L2.106,11.124 L2.106,7.128 Z" id="形状"></path>
                            <path d="M16.2,3.294 C14.814,3.294 13.716,3.762 12.924,4.716 C12.096,5.652 11.7,6.804 11.7,8.19 C11.7,9.738 12.132,10.944 12.996,11.826 C13.806,12.672 14.904,13.104 16.29,13.104 C17.532,13.104 18.558,12.744 19.386,12.042 C20.034,11.466 20.466,10.728 20.682,9.864 L18.63,9.864 C18.378,10.386 18.108,10.764 17.802,10.998 C17.406,11.286 16.902,11.43 16.272,11.43 C15.534,11.43 14.958,11.196 14.562,10.746 C14.166,10.296 13.932,9.63 13.86,8.766 L20.826,8.766 C20.826,7.092 20.448,5.778 19.71,4.842 C18.9,3.798 17.73,3.294 16.2,3.294 Z M16.254,4.968 C17.694,4.968 18.504,5.706 18.684,7.218 L13.896,7.218 C14.022,6.48 14.274,5.922 14.652,5.544 C15.048,5.148 15.57,4.968 16.254,4.968 Z" id="形状"></path>
                            <path d="M25.362,0.54 L23.31,1.386 L23.31,3.546 L21.636,3.546 L21.636,5.238 L23.31,5.238 L23.31,10.494 C23.31,11.25 23.49,11.808 23.85,12.204 C24.228,12.636 24.822,12.852 25.632,12.852 L27.198,12.852 L27.198,11.16 L25.974,11.16 C25.758,11.16 25.614,11.106 25.506,10.998 C25.398,10.872 25.362,10.71 25.362,10.494 L25.362,5.238 L27.432,5.238 L27.432,3.546 L25.362,3.546 L25.362,0.54 Z" id="path10"></path>
                            <path d="M33.192,3.294 C32.004,3.294 31.068,3.492 30.384,3.924 C29.574,4.392 29.07,5.148 28.872,6.174 L30.906,6.336 C31.014,5.85 31.284,5.49 31.68,5.256 C32.022,5.04 32.472,4.95 33.048,4.95 C34.38,4.95 35.046,5.544 35.046,6.768 L35.046,7.128 L33.012,7.182 C31.644,7.218 30.582,7.506 29.826,8.046 C28.998,8.604 28.602,9.414 28.602,10.458 C28.602,11.232 28.89,11.862 29.466,12.366 C30.024,12.852 30.78,13.104 31.752,13.104 C32.562,13.104 33.264,12.96 33.858,12.69 C34.38,12.438 34.83,12.096 35.19,11.628 L35.19,12.852 L37.098,12.852 L37.098,6.912 C37.098,5.778 36.81,4.914 36.234,4.32 C35.586,3.636 34.56,3.294 33.192,3.294 Z M35.046,8.604 L35.046,9.072 C35.046,9.792 34.758,10.386 34.218,10.854 C33.678,11.304 33.012,11.538 32.22,11.538 C31.77,11.538 31.41,11.412 31.122,11.196 C30.834,10.98 30.708,10.71 30.708,10.386 C30.708,9.27 31.518,8.694 33.156,8.658 L35.046,8.604 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>