<template>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="indexon"
        transform="translate(-1617.000000, -27.000000)"
        fill="#FFFFFF"
        fill-rule="nonzero"
      >
        <g id="search" transform="translate(1617.000000, 27.000000)">
          <path
            d="M17.8749968,16.460247 L13.8749968,12.4554252 C13.7454271,12.32264 13.6011627,12.2050739 13.4449968,12.1050033 L12.4449968,11.4141716 L12.4449968,11.4141716 C14.5019208,8.8659643 14.521023,5.23098555 12.4909944,2.66124913 C10.4609657,0.0915127036 6.92358975,-0.727158125 3.9730966,0.689913738 C1.02260345,2.1069856 -0.55356549,5.38160539 0.177727934,8.57510233 C0.909021359,11.7685993 3.75260614,14.028678 7.02499685,14.0173057 C8.6130434,14.0177683 10.1540519,13.4775887 11.3949968,12.4854614 L11.3949968,12.4854614 L12.1449968,13.4866668 C12.2339567,13.6156076 12.3343645,13.7362421 12.4449968,13.8471008 L16.4449968,17.8519226 C16.5388801,17.946693 16.6666781,18 16.7999968,18 C16.9333156,18 17.0611135,17.946693 17.1549968,17.8519226 L17.8549968,17.1510787 C18.0448281,16.9630862 18.0536335,16.6589327 17.8749968,16.460247 Z M7.02499685,12.0148948 C4.2635731,12.0148948 2.02499685,9.77362009 2.02499685,7.0088676 C2.02499685,4.24411511 4.2635731,2.00284039 7.02499685,2.00284039 C9.7864206,2.00284039 12.0249968,4.24411511 12.0249968,7.0088676 C12.0249968,8.33654857 11.4982126,9.60985118 10.5605308,10.5486634 C9.62284886,11.4874756 8.3510793,12.0148948 7.02499685,12.0148948 Z"
            id="Icon-color"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
