<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.15"
      d="M11.3667 20.9001C16.8344 20.9001 21.2667 16.4678 21.2667 11.0001C21.2667 9.0635 20.7107 7.25674 19.7495 5.73083C19.5962 5.72378 19.442 5.72021 19.287 5.72021C13.8193 5.72021 9.38696 10.1526 9.38696 15.6202C9.38696 17.5568 9.94304 19.3636 10.9042 20.8895C11.0575 20.8966 11.2117 20.9001 11.3667 20.9001Z"
      fill="#00C0AB"
    />
    <path
      d="M20.6666 11.0001C20.6666 16.1363 16.5028 20.3001 11.3666 20.3001C6.2303 20.3001 2.06655 16.1363 2.06655 11.0001C2.06655 5.86385 6.2303 1.7001 11.3666 1.7001C16.5028 1.7001 20.6666 5.86385 20.6666 11.0001Z"
      stroke="#FAFAFA"
      stroke-width="1.2"
    />
    <path
      d="M14.2076 8.2076C14.2076 9.97916 12.7714 11.4153 10.9999 11.4153C9.22833 11.4153 7.79219 9.97916 7.79219 8.2076C7.79219 6.43604 9.22833 4.9999 10.9999 4.9999C12.7714 4.9999 14.2076 6.43604 14.2076 8.2076Z"
      stroke="#FAFAFA"
      stroke-width="1.2"
    />
    <path
      d="M18.26 16.8388C16.7403 14.432 14.0569 12.8335 11 12.8335C7.9432 12.8335 5.25972 14.4321 3.73999 16.8389"
      stroke="#FAFAFA"
      stroke-width="1.2"
    />
  </svg>
</template>
