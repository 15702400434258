<template>
  <div class="header-outer-wrapper">
    <div class="app-header-wrapper">
      <div class="header-left-wrapper">
        <div class="logo-icon-wrapper">
          <NuxtLink to="/">
            <LogoIcon />
          </NuxtLink>
        </div>
        <div class="left-other-nav-wrapper responsive is-adaptive">
          <div class="nav-item-wrapper" :class="[ route.path === '/fundraising' ? 'active' : '']">
            <NuxtLink to="/fundraising">Fundraising</NuxtLink>
          </div>
          <div class="nav-item-wrapper" :class="[ route.path === '/projects' ? 'active' : '']">
            <NuxtLink to="/projects">Projects</NuxtLink>
          </div>
          <div class="nav-item-wrapper" :class="[ route.path === '/investors' ? 'active' : '']">
            <NuxtLink to="/investors">Investors</NuxtLink>
          </div>
          <div class="nav-item-wrapper" :class="[ route.path === '/people' ? 'active' : '']">
            <NuxtLink to="/people">People</NuxtLink>
          </div>
          <div class="nav-item-wrapper" :class="[ route.path === '/dashboard' ? 'active' : '']">
            <NuxtLink to="/dashboard">Dashboard</NuxtLink>
          </div>
          <div class="nav-item-wrapper" :class="[ route.path === '/tools' ? 'active' : '']">
            <NuxtLink to="/tools">Tools</NuxtLink>
          </div>
          <!-- <ClientOnly>
            <div class="nav-item-more-wrapper">
              <el-dropdown
                trigger="click"
                placement="bottom"
                popper-class="common-popover"
              >
                <div class="more-nav-button">
                  <div class="button-text">{{ `More` }}</div>
                  <div class="chevron-down-wrapper">
                    <ChevronDown />
                  </div>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <div class="dropdown-list-item">
                        <NuxtLink to="/people">People</NuxtLink>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="dropdown-list-item">
                        <NuxtLink to="/datachart">Datachart</NuxtLink>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="dropdown-list-item">
                        <NuxtLink to="/tools">Tools</NuxtLink>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </ClientOnly> -->
        </div>
      </div>
      <div class="header-right-wrapper">
        <div class="right-nav-wrapper">
          <div class="nav-item-wrapper search">
            <NuxtLink to="/search">
              <Search />
            </NuxtLink>
          </div>
          <div class="nav-item-wrapper login-button" v-if="!userStore.isLogin">
            <NuxtLink to="/login?scheme=login">Log In</NuxtLink>
          </div>
          <div
            class="nav-item-wrapper register-button"
            v-if="!userStore.isLogin"
          >
            <NuxtLink to="/login?scheme=signup">Sign Up</NuxtLink>
          </div>
          <div
            class="nav-item-more-wrapper phone-hidden"
            v-if="userStore.isLogin"
          >
            <ClientOnly>
              <el-dropdown
                trigger="hover"
                placement="bottom"
                popper-class="common-popover"
              >
                <div class="more-nav-button user-info-icon">
                  <UserIcon :iconUrl="avatarUrl" />
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <div class="dropdown-list-item">
                        <NuxtLink to="/profile/personal">
                          <div
                            class="row-inner-item-wrapper"
                            :class="[
                              route.path === '/profile/personal'
                                ? 'active'
                                : '',
                            ]"
                          >
                            <div class="nav-icon-wrapper">
                              <SettingIcon />
                            </div>
                            <div class="nav-text-wrapper">
                              Personal Information
                            </div>
                          </div>
                        </NuxtLink>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="dropdown-list-item">
                        <NuxtLink to="/profile/password">
                          <div
                            class="row-inner-item-wrapper"
                            :class="[
                              route.path === '/profile/password'
                                ? 'active'
                                : '',
                            ]"
                          >
                            <div class="nav-icon-wrapper">
                              <LockIcon />
                            </div>
                            <div class="nav-text-wrapper">
                              Password Management
                            </div>
                          </div>
                        </NuxtLink>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="dropdown-list-item">
                        <NuxtLink to="/profile/submit">
                          <div
                            class="row-inner-item-wrapper"
                            :class="[
                              route.path === '/profile/submit'
                                ? 'active'
                                : '',
                            ]"
                          >
                            <div class="nav-icon-wrapper">
                              <SubmitIcon />
                            </div>
                            <div class="nav-text-wrapper">
                              Project Submit
                            </div>
                          </div>
                        </NuxtLink>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="dropdown-list-item">
                        <NuxtLink to="/profile/favorites?subNavTag=1">
                          <div
                            class="row-inner-item-wrapper"
                            :class="[
                              route.path === '/profile/favorites'
                                ? 'active'
                                : '',
                            ]"
                          >
                            <div class="nav-icon-wrapper">
                              <StarIcon />
                            </div>
                            <div class="nav-text-wrapper">My Favorites</div>
                          </div>
                        </NuxtLink>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="dropdown-list-item" @click="logOutHandle">
                        <div class="row-inner-item-wrapper">
                          <div class="nav-icon-wrapper">
                            <SignOutIcon />
                          </div>
                          <div class="nav-text-wrapper">Logout</div>
                        </div>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </ClientOnly>
          </div>
          <!-- <div class="nav-item-wrapper">
            <DarkModeToggle />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore, useRoute, onMounted, navigateTo, computed } from "#imports";
import { useDark } from "@vueuse/core";
import { useToggle } from "@vueuse/shared";
import LogoIcon from "./icons/Logo.vue";

const userStore = useUserStore();
const route = useRoute();

const isDark = useDark({
  selector: "html",
  attribute: "data-theme",
  valueDark: "dark",
  valueLight: "light",
});

const avatarUrl = computed(() => {
  let _avatar = userStore.getUserInfo?.avatar || "";
  return _avatar;
});

const toggleDark = useToggle(isDark);

// test code
toggleDark(true);

const logOutHandle = () => {
  userStore.setToken("");
  navigateTo("/login");
};
</script>

<style lang="scss" scoped>
// .is-adaptive 自适应
.app-header-wrapper {
  height: 68px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  background: #171717;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(40px);
  .header-left-wrapper {
    flex-shrink: 0;
    height: 68px;
    display: grid;
    grid-auto-flow: column;
    gap: 24px;
    align-items: center;
    .logo-icon-wrapper {
      height: 30px;
      width: 151px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      a {
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #20d691;
        font-size: 18px;
        font-weight: 700;
        svg{
          height: 30px;
          width: 151px;
        }
      }
    }
    .left-other-nav-wrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 24px;
      align-items: center;
      // @media screen and (max-width: 930px) {
      //   &.responsive{
      //     display: none;
      //   }
      // }
      .nav-item-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
          display: inline-block;
          height: 28px;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
        }
        &:hover{
          a{
            color: #1EC174 !important;
          }
        }
        &.active{
          a{
            color: #1EC174 !important;
          }
        }
      }
      .nav-item-more-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .header-right-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .right-nav-wrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 24px;
      align-items: center;
      .nav-item-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
          display: inline-block;
          height: 28px;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
        }
        &.search {
          font-size: 16px;
          font-weight: 700;
          // margin-right: 40px;
        }
        &.login-button {
          height: 28px;
          // min-width: 60px;
          // padding-left: 14px;
          // padding-right: 14px;
          border-radius: 6px;
          color: #ffffff;
          a {
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
          }
        }
        &.register-button {
          height: 28px;
          min-width: 60px;
          padding-left: 14px;
          padding-right: 14px;
          background: #1ec174;
          border-radius: 4px;
          color: #ffffff;
          a {
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
          }
        }
        &.phone-nav-button {
          display: none;
        }
        // @media screen and (max-width: 930px) {
        //   &.phone-nav-button {
        //     display: inline-flex;
        //   }
        // }
        &.user-info-icon {
          height: 24px;
          width: 24px;
          overflow: hidden;
          border-radius: 50%;
        }
      }
      .nav-item-more-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.more-nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.user-info-icon {
    height: 24px;
    width: 24px;
    overflow: hidden;
    border-radius: 50%;
  }
  .chevron-down-wrapper {
    margin-left: 6px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .button-text {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
}
.dropdown-list-item {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  a {
    display: inline-block;
    width: 100%;
    height: 28px;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
}
.row-inner-item-wrapper {
  height: 28px;
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .nav-icon-wrapper {
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  .nav-text-wrapper {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
  }
  &.active {
    color: #20d691;
    .nav-icon-wrapper {
      svg {
        :deep(.flag-svg-icon) {
          fill: #1ec174 !important;
        }
      }
    }
    .nav-text-wrapper {
      color: #1ec174 !important;
    }
  }
  &:hover {
    color: #20d691;
    .nav-icon-wrapper {
      svg {
        :deep(.flag-svg-icon) {
          fill: #1ec174 !important;
        }
      }
    }
    .nav-text-wrapper {
      color: #1ec174 !important;
    }
  }
}
// @media screen and (max-width: 930px) {
//   .phone-hidden {
//     display: none !important;
//   }
// }

// @media screen and (min-width: 931px) {
//   .web-hidden {
//     display: none !important;
//   }
// }
</style>
